import React from "react";
import Layout from "../../../other/layout";
import OfficeSupplies from "../../../components/AC-StaticPages/products/OfficeSupplies";
import SEO from "../../../other/seo";

export default function officeSupplies() {
  return (
    <Layout>
      <SEO title="Office Supplies" />

      <OfficeSupplies />
    </Layout>
  );
}
