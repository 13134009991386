import React from "react";
import { Link } from "gatsby";
import BusinessOfficeSupplies_Hero from "../../../assets/img/BusinessOfficeSupplies_Hero.jpg";
import BusinessCardsLetterhead_Thumbnail from "../../../assets/img/BusinessCardsLetterhead_Thumbnail.jpg";
import envelopes from "../../../assets/img/envelopes.jpg";
import stamps from "../../../assets/img/stamps-cat.jpg";
import tags from "../../../assets/img/key-tags.jpg";
import labels from "../../../assets/img/labels-stickers.jpg";
import calendars from "../../../assets/img/holiday-calendars.jpg";

import styles from "../style/styles.module.css";

export default function OfficeSupplies() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Office Supplies</h1>
          <h2>
            Everything to keep your customers' businesses running smoothly.
          </h2>
          <p className={styles.productParag}>
            Shop DFS for business and office supplies.
          </p>
          <img
            className={styles.hero}
            src={BusinessOfficeSupplies_Hero}
            alt="Man and woman working together on a laptop Image"
          />

          <p className={styles.productMargin}>
            From business cards to gift certificates, greeting cards, tags and
            more, easily offer the essential office supplies every business
            needs to perform at its best - all at competitive prices.
          </p>
          <hr style={{ border: "1px solid #d3dce2", width: "100%" }} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Product Category Highlights</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img
                src={BusinessCardsLetterhead_Thumbnail}
                alt="Business card and letterhead Image"
              ></img>
              <Link to="/products/office-supplies/stationery/">Stationery</Link>
            </div>
            <div className={styles.card}>
              <img src={envelopes} alt="Envelopes Image"></img>
              <Link to="/products/office-supplies/stationery/envelopes/">
                Envelopes
              </Link>
            </div>
            <div className={styles.card}>
              <img src={stamps} alt="Stamps Image"></img>
              <Link to="/products/banking-products/stamps/">Stamps</Link>
            </div>
            <div className={styles.card}>
              <img src={tags} alt="Key tags Image"></img>
              <Link to="/products/office-supplies/tags/">Tags</Link>
            </div>
            <div className={styles.card}>
              <img src={labels} alt="Labels Image"></img>
              <Link to="/products/office-supplies/labels/">Labels</Link>
            </div>
            <div className={styles.card}>
              <img src={calendars} alt="Calendars Image"></img>
              <Link to="/products/holiday-cards-calendars/calendars/">
                Calendars
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
